import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from '../pages/landing/Landing'
import MainLayout from '../layout/mainLayout/MainLayout'
import Hero from '../pages/landing/Hero'
import About from '../pages/landing/About'
import Account from '../pages/landing/Account'
import Opinions from '../pages/landing/Opinions'
import Programs from '../pages/landing/Programs'

const AppRoute = () => {
    return (
        <Routes>

            <Route
                exact
                path="/"
                index
                element={<MainLayout>
                    <Landing />
                </MainLayout>}
            />

            <Route path="/hero" element={<Hero />} />
            <Route path="/about" element={<About />} />
            <Route path="/account" element={<Account />} />
            <Route path="/opinions" element={<Opinions />} />
            <Route path="/programs" element={<Programs />} />
        </Routes>
    )
}

export default AppRoute