import { Button, Card, Col, Image, Row, Tabs } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import laptop from '../../images/laptop.png'
import { FaStar } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react';


const CardDesign = () => {
    return (
        <Col span={22}>
            <Card className='myCard'>
                <Image src={laptop} className='cardImag' width={'100%'} height={240} />
                <svg className='logo' width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="64" height="40" rx="7.34988" fill="white" />
                    <path d="M21.0698 30.8592C20.6305 30.8543 20.2742 30.5018 20.2648 30.0625L20.0354 19.3715C20.0197 18.6399 20.902 18.2605 21.4222 18.7753L32.3444 29.5846C32.8645 30.0994 32.4944 30.9856 31.7626 30.9775L21.0698 30.8592Z" fill="url(#paint0_linear_199_312)" />
                    <path d="M20.635 15.2769C20.577 15.2182 20.5434 15.1397 20.5411 15.0572L20.3547 8.47839C20.3468 8.20052 20.5721 7.97209 20.85 7.97607L27.6048 8.07261C27.6902 8.07383 27.7718 8.10855 27.8318 8.1693L34.1108 14.521C34.4269 14.8408 34.4239 15.3563 34.1041 15.6724L28.0689 21.6386C27.7491 21.9548 27.2335 21.9518 26.9174 21.632L20.635 15.2769Z" fill="url(#paint1_linear_199_312)" />
                    <path d="M29.0982 23.605L36.0288 16.4874L42.9219 23.1994C41.4313 26.1894 39.0579 28.649 36.123 30.2454L35.9913 30.317L29.0982 23.605Z" fill="url(#paint2_linear_199_312)" />
                    <path d="M40.5945 17.6024L33.4337 10.7063L30.8778 7.83594L32.6566 7.83594C34.9251 7.83594 37.1367 8.54564 38.9816 9.8656L39.1019 9.95168C39.802 10.4526 40.4327 11.0439 40.9777 11.7103C42.5217 13.5984 43.3026 15.9963 43.1664 18.4316L43.071 20.1389L40.5945 17.6024Z" fill="url(#paint3_linear_199_312)" />
                    <defs>
                        <linearGradient id="paint0_linear_199_312" x1="41.3104" y1="18.8865" x2="25.5192" y2="24.9967" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0FB3ED" />
                            <stop offset="0.996" stop-color="#0096D8" />
                            <stop offset="1" stop-color="#0096D8" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_199_312" x1="43.1816" y1="19.4068" x2="20.0352" y2="19.4068" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#565656" />
                            <stop offset="1" stop-color="#8C8383" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_199_312" x1="25.3452" y1="30.9776" x2="41.9993" y2="23.3123" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0FB3ED" />
                            <stop offset="1" stop-color="#164EAB" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_199_312" x1="34.7033" y1="7.8765" x2="37.584" y2="20.0584" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFD056" />
                            <stop offset="1" stop-color="#FFB400" />
                        </linearGradient>
                    </defs>
                </svg>
                <div className='p-3'>
                    <Row justify={'space-between'} align={'middle'}>
                        <Col><Title className='bold-600 font-14 color-darkGrey'>Design</Title></Col>
                        <Col><FaStar color='#FFB400' size={20} /> <span className='bold-600 font-14 color-darkGrey mt-3'>5.0</span></Col>

                    </Row>


                    <Title className='bold-700 font-16 color-darkGrey mt-2'>UX/UI Design 201</Title>
                    <Title className='bold-400 font-14 color-darkGrey mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.</Title>
                    <Title className='color-grey bold-500 font-12'>2 months</Title>
                    <Row justify={'space-between'} align={'middle'} className='mt-3'>
                        <Title className='bold-500 font-16 color-darkGrey'>$400</Title>

                        <Col span={10}>
                            <Button className='cusBtn' block> Enroll Now</Button>
                        </Col>
                    </Row>



                </div>
            </Card>

        </Col>

    )
}

const Programs = () => {
    const programsData = Array(8).fill({});
    const items = [
        {
            key: '1',
            label: 'Popular',
            children:
                (<Swiper
                    modules={[Navigation, Pagination]}
                    navigation={true}
                    pagination={{ clickable: true }}
                    slidesPerView={3.5}
                    spaceBetween={30}
                    breakpoints={{
                      
                        320: {
                            slidesPerView: 1.2,
                            spaceBetween: 20
                        },
                       
                        480: {
                            slidesPerView: 1.5,
                            spaceBetween: 20
                        },
                     
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 30
                        },
                      
                        1024: {
                            slidesPerView: 3.5,
                            spaceBetween: 30
                        }
                    }}
                
                    className="programsSwiper"
                >
                    {/* <Row> */}
                    {programsData.map((_, index) => (
                        <SwiperSlide key={index}>
                            <CardDesign />
                        </SwiperSlide>
                    ))}
                    {/* </Row> */}
                </Swiper>),
        },
        {
            key: '2',
            label: 'Marketing',
            children: 'Content of Tab Pane 2',
        },
        {
            key: '3',
            label: 'Development',
            children: 'Content of Tab Pane 3',
        },
        {
            key: '4',
            label: 'Business',
            children: 'Content of Tab Pane 3',
        },
    ];
    return (
        <div className='Programs ' id="programs">
            <Row className='myContainer' justify={'center'}>

                <Title className='bigTitle'>Our Programs</Title>
                <Col span={24} />

                <Title className='subTitle'>A choice that makes the difference</Title>
                <Col span={24}>
                    <Tabs defaultActiveKey="1" items={items} className='customTabs mt-4' centered tabBarGutter={16} />


                </Col>
                <Button className='viewALl mt-4'>
                    View All
                </Button>

            </Row>

        </div>
    )
}

export default Programs