import { Col, Divider, Row, Tag } from 'antd'
import Link from 'antd/es/typography/Link'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { BsTwitterX } from 'react-icons/bs'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { FiPhoneCall } from 'react-icons/fi'
import { IoIosArrowDown } from 'react-icons/io'
import { MdOutlineEmail } from 'react-icons/md'

const MyFooter = () => {
  return (
    <div className='MyFooter'>

      <Row className='myContainer' gutter={[16, 0]}>
        <Col >
          <svg width="42" height="45" viewBox="0 0 42 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.87745 43.6787C1.08022 43.6699 0.433781 43.0302 0.416676 42.2331L0.000375111 22.834C-0.0281147 21.5064 1.57295 20.8181 2.51678 21.7522L22.3354 41.366C23.2793 42.3 22.6076 43.9082 21.2798 43.8935L1.87745 43.6787Z" fill="url(#paint0_linear_139_13916)" />
            <path d="M1.08847 15.4042C0.98318 15.2977 0.922254 15.1552 0.918013 15.0055L0.5798 3.0681C0.565515 2.5639 0.974235 2.1494 1.47859 2.15661L13.7354 2.33179C13.8904 2.334 14.0383 2.39701 14.1473 2.50723L25.5406 14.0325C26.1143 14.6128 26.1089 15.5483 25.5286 16.1219L14.5774 26.9477C13.9971 27.5214 13.0616 27.516 12.488 26.9357L1.08847 15.4042Z" fill="url(#paint1_linear_139_13916)" />
            <path d="M16.4452 30.5158L29.0209 17.6007L41.5287 29.7798C38.8239 35.2052 34.5173 39.6683 29.1919 42.5649L28.9529 42.6949L16.4452 30.5158Z" fill="url(#paint2_linear_139_13916)" />
            <path d="M37.3056 19.6238L24.3121 7.11072L19.6743 1.90234L22.902 1.90234C27.0182 1.90234 31.0313 3.19012 34.3789 5.58522L34.5972 5.74142C35.8675 6.65029 37.012 7.72327 38.0008 8.93242C40.8025 12.3585 42.2194 16.7096 41.9724 21.1285L41.7992 24.2264L37.3056 19.6238Z" fill="url(#paint3_linear_139_13916)" />
            <defs>
              <linearGradient id="paint0_linear_139_13916" x1="38.6047" y1="21.9539" x2="9.9509" y2="33.0411" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0FB3ED" />
                <stop offset="0.996" stop-color="#0096D8" />
                <stop offset="1" stop-color="#0096D8" />
              </linearGradient>
              <linearGradient id="paint1_linear_139_13916" x1="42" y1="22.898" x2="0" y2="22.898" gradientUnits="userSpaceOnUse">
                <stop stop-color="#565656" />
                <stop offset="1" stop-color="#8C8383" />
              </linearGradient>
              <linearGradient id="paint2_linear_139_13916" x1="9.63529" y1="43.8936" x2="39.8547" y2="29.9847" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0FB3ED" />
                <stop offset="1" stop-color="#164EAB" />
              </linearGradient>
              <linearGradient id="paint3_linear_139_13916" x1="26.6159" y1="1.97594" x2="31.8429" y2="24.0804" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFD056" />
                <stop offset="1" stop-color="#FFB400" />
              </linearGradient>
            </defs>
          </svg>

        </Col>
        <Col span={20} className='mb-5'>
          <Title className='bigTitle'>Great Lakes Online</Title>
        </Col>

        <Col span={8}>

          <Row gutter={[16, 24]} align={'middle'}>
            <Col> <MdOutlineEmail color='white' size={30} /></Col>

            <Col span={20}>   <Title className='subTitle'>Info@GreatLakesonline.net</Title></Col>
            <Col> <FiPhoneCall color='white' size={30} /></Col>

            <Col span={20}>   <Title className='subTitle'>+1-647-546- 3842</Title></Col>

            <Col className='mainCol'>
              <FaFacebookF />
            </Col>
            <Col className='mainCol'>
              <FaInstagram />
            </Col>
            <Col className='mainCol'>
              <FaLinkedinIn />
            </Col>
            <Col className='mainCol'>
              <BsTwitterX />
            </Col>
            <Col className='mainCol'>
              <FaYoutube />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Title className='Title'>
            Programs
          </Title>
          <Title className='TitleSub'>
            Marketing
          </Title>
          <Title className='TitleSub'>
            Development
          </Title>
          <Title className='TitleSub'>
            Business
          </Title>
          <Title className='TitleSub'>
            Design
          </Title>
        </Col>
        <Col span={8}>
          <Title className='Title'>
            Quick Links
          </Title>
          <Title className='TitleSub'>
            About
          </Title>
          <Title className='TitleSub'>
            Contact
          </Title>
          <Title className='TitleSub'>
            Help/ Support
          </Title>

          <Title className='TitleSub'>
            FAQ
          </Title>
        </Col>
      </Row>

      <Divider className='divider' />
      <Row className='myContainer copyRight' justify={'space-between'}>

        <Col>
          <Row gutter={24}>
            <Col>
              <Title className='title'>
                © 2024 . All rights reserved

              </Title>
            </Col>
            <Col>
              <Link className='title underline'>
                Privacy Policy

              </Link>
            </Col>
            <Col>
              <Link className='title underline'>
                Terms of Service

              </Link>
            </Col>
            <Col>
              <Link className='title underline'>
                Cookies Settings

              </Link>
            </Col>



          </Row>
        </Col>
        <Col>
        <Tag className='tag'>
          English <IoIosArrowDown />
        </Tag>
        </Col>

      </Row>
    </div>
  )
}

export default MyFooter