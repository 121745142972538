import { Button, Col, Form, Input, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Title from 'antd/es/typography/Title'
import React from 'react'

const ContactUs = () => {
    const onFinish = (values) => {
        console.log(values);
    };
    return (
        <div className='ContactUs'>
            <svg className='arrow' width="357" height="451" viewBox="0 0 357 451" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_382_399)">
                    <path d="M-202.279 -81.4934C-201.551 -99.9543 -181.112 -110.705 -165.488 -100.844L345.11 221.411C360.734 231.272 359.824 254.348 343.473 262.948L-190.907 544.012C-207.259 552.612 -226.788 540.286 -226.061 521.825L-202.279 -81.4934Z" fill="url(#paint0_radial_382_399)" />
                </g>
                <defs>
                    <filter id="filter0_b_382_399" x="-364.696" y="-243.196" width="859.611" height="928.618" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="69.307" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_382_399" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_382_399" result="shape" />
                    </filter>
                    <radialGradient id="paint0_radial_382_399" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-687.488 -210.969) rotate(26.5492) scale(1326.76 1408.38)">
                        <stop stop-color="#123958" stop-opacity="0.5" />
                        <stop offset="1" stop-color="#277BBE" stop-opacity="0" />
                    </radialGradient>
                </defs>
            </svg>

            <Row justify={'center'} className='myContainer'>
                <Col span={24}>
                    <Title className='color-darkGrey bold 700 font-32 text-center'>
                        Contact Us
                    </Title></Col>
                <Col span={16}>
                    <Title className='color-grey bold 400 font-18 text-center'>
                        Here to help you! Reach out to us for the information and support you need to achieve your academic goals. Our team is available to answer all your questions and provide the necessary guidance.
                    </Title>
                </Col>
                <Col lg={10} sm={12} className='myCard mt-4'>

                    <Form
                        layout='vertical'
                        name="Contact-form"
                        onFinish={onFinish}
                    >
                        <Row justify={'center'} className='w-100' gutter={[0, 12]}>


                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'name']}


                                >

                                    <Input placeholder="Full Name" />
                                </Form.Item>
                            </Col>

                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Address']}


                                >

                                    <Input placeholder="Address" />
                                </Form.Item>
                            </Col>

                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Email']}


                                >

                                    <Input placeholder="Email Address" />
                                </Form.Item>
                            </Col>

                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Note']}


                                >

                                    <TextArea 
                                    placeholder="Note"
                                    autoSize={{
                                        minRows: 6,
                                        // maxRows: 5,
                                      }}
                                    />
                                </Form.Item>
                            </Col>


                            <Col span={20}>

                                <Button
                                    htmlType="submit"
                                    type='primary'
                                    className='cusBtn'
                                    block
                                > Send</Button>
                            </Col>


                        </Row>
                    </Form>




                </Col>
            </Row>

        </div>
    )
}

export default ContactUs