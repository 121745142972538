import { Card, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { LuShieldCheck } from 'react-icons/lu'



const AccreditationCard = ({ bgColor, titleUrl, mainTitle, description }) => {
    return (
        <Col span={24}>
            <Row gutter={36}>
                <Col span={6} className={`cusCard ${bgColor}`}>
                    <LuShieldCheck size={48} color='white' />
                </Col>
                <Col span={18}>
                    <a href={titleUrl} target="_blank" rel="noopener noreferrer">
                        <Title className='color-darkGrey font-18 bold-400 underline'>
                            {mainTitle}
                        </Title>
                    </a>
                    <Title className='color-grey font-18 bold-400 mt-0'>
                        {description}
                    </Title>
                </Col>
            </Row>
        </Col>
    );
};

const Accreditation = () => {
    return (
        <div id='Accreditation' className='accreditation'>
                  <svg className='shield' width="339" height="580" viewBox="0 0 339 580" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.08" d="M201.103 392.765L364.876 228.992L323.571 187.686L201.103 310.154L140.232 249.282L98.9266 290.588L201.103 392.765ZM231.539 579.727C164.387 562.818 108.956 524.285 65.2445 464.129C21.5331 403.973 -0.332238 337.188 -0.351562 263.776V86.959L231.539 0L463.43 86.959V263.776C463.43 337.208 441.574 404.002 397.863 464.158C354.151 524.314 298.71 562.837 231.539 579.727ZM231.539 518.855C281.782 502.913 323.329 471.028 356.18 423.2C389.031 375.373 405.457 322.231 405.457 263.776V126.815L231.539 61.5959L57.6211 126.815V263.776C57.6211 322.231 74.0467 375.373 106.898 423.2C139.749 471.028 181.296 502.913 231.539 518.855Z" fill="url(#paint0_linear_382_172)" />
                            <defs>
                                <linearGradient id="paint0_linear_382_172" x1="231.539" y1="0" x2="231.539" y2="579.727" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0FB3ED" />
                                    <stop offset="1" stop-color="#164EAB" />
                                </linearGradient>
                            </defs>
                        </svg>
            <Row className='myContainer mainRow' gutter={[20,0]} justify={'space-between'}>  
                <Col xl={10}>
                    <Card className='contentCard'>
                        <Row>
                            <Col span={6} className='mt-4'>
                                <svg className='logo' width="110" height="130" viewBox="0 0 110 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.87496 118.959C2.80488 118.936 1.12635 117.275 1.08194 115.205L0.000974008 64.8338C-0.0730022 61.3866 4.0843 59.5993 6.53503 62.0247L57.9959 112.954C60.4466 115.379 58.7027 119.555 55.2549 119.517L4.87496 118.959Z" fill="url(#paint0_linear_382_177)" />
                                    <path d="M2.82631 45.5417C2.55291 45.2651 2.39471 44.895 2.3837 44.5063L1.5055 13.5099C1.46841 12.2007 2.52969 11.1244 3.83929 11.1431L35.6652 11.598C36.0676 11.6037 36.4516 11.7674 36.7346 12.0536L66.3184 41.98C67.8079 43.4867 67.794 45.9158 66.2872 47.4053L37.8515 75.5155C36.3447 77.005 33.9157 76.991 32.4261 75.4842L2.82631 45.5417Z" fill="url(#paint1_linear_382_177)" />
                                    <path d="M42.7014 84.7803L75.3553 51.2451L107.833 82.8692C100.81 96.9566 89.6272 108.546 75.7993 116.067L75.1788 116.404L42.7014 84.7803Z" fill="url(#paint2_linear_382_177)" />
                                    <path d="M96.8673 56.4982L63.1285 24.0069L51.0859 10.4829L59.467 10.4829C70.1551 10.4829 80.5754 13.8267 89.2678 20.0458L89.8347 20.4514C93.1332 22.8114 96.1049 25.5975 98.6724 28.7371C105.947 37.6332 109.626 48.9313 108.985 60.4052L108.535 68.4493L96.8673 56.4982Z" fill="url(#paint3_linear_382_177)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_382_177" x1="100.24" y1="62.5485" x2="25.8384" y2="91.3374" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#0FB3ED" />
                                            <stop offset="0.996" stop-color="#0096D8" />
                                            <stop offset="1" stop-color="#0096D8" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_382_177" x1="109.057" y1="64.9998" x2="0" y2="64.9998" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#565656" />
                                            <stop offset="1" stop-color="#8C8383" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_382_177" x1="25.0189" y1="119.517" x2="103.486" y2="83.4011" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#0FB3ED" />
                                            <stop offset="1" stop-color="#164EAB" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_382_177" x1="69.1105" y1="10.674" x2="82.6829" y2="68.0701" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FFD056" />
                                            <stop offset="1" stop-color="#FFB400" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </Col>
                            <Col span={24}>
                                <Title className='color-darkGrey font-32 bold-700 mt-5'>Accreditation </Title>

                            </Col>
                            <Col span={24}>
                                <Title className='color-darkGrey font-18 bold-400 line-32 mt-3'>
                                    Great Lakes Online is the online arm of Great Lakes College of Science and Technology,
                                    <br />
                                    located in Windsor<span className='bold-700'> (1382221 Ontario Limited)</span>.


                                </Title>

                            </Col>
                            <Col span={24} className='mt-5'>
                                <Title className='color-darkGrey font-18 bold-400 line-32'>
                                    We take pride in offering exceptional education accredited by recognized bodie . <FaArrowRightLong />



                                </Title>

                            </Col>
                        </Row>


                    </Card>

                </Col>
                <Col xl={12}>
                    <Row gutter={[0, 56]}>
                  


                        <AccreditationCard
                            bgColor="grey"
                            titleUrl='https://www.pcc.tcu.gov.on.ca/PARISSearchWeb/search.xhtml'
                            mainTitle="Ministry of Colleges and Universities."
                            description="A non-profit organization dedicated to improving the quality of education and vocational training in Canada."
                        />
                        <AccreditationCard
                            bgColor="blue"
                            titleUrl='https://nacc.ca/curriculum/membership-database/?appSession=6IJDOJ38T720BE5XTCK40BZC2XS1L5IEHRE93U8R75E4KVP3T13E5RZM8NT65490A778066A6U5ES144PJ0BYZ0791O24G01Q685W48GTUUVS8Q44205OQ2SB46L5T6E&PageID=8&PrevPageID=2&cpipage=1&RecordID=1670&cbCurrentRecordPosition=1&Mod0LinkToDetails=True&cbCurrentPageSize=10'
                            mainTitle="National Association of Career Colleges (NACC)"
                            description="A non-profit organization dedicated to improving the quality of education and vocational training in Canada."
                        />
                        <AccreditationCard
                            bgColor="darkBlue"
                            titleUrl='https://careercollegesontario.ca/cco-member-colleges/'
                            mainTitle="Career Colleges of Ontario (CCO)"
                            description="The association representing career colleges in Ontario, supporting the development of vocational education."
                        />
                        <AccreditationCard
                            bgColor="orange"
                            titleUrl='https://www.pcc.tcu.gov.on.ca/PARISSearchWeb/search.xhtml'
                            mainTitle="Technical Standards and Safety Authority (TSSA)"
                            description="A regulatory authority that ensures educational programs comply with safety and industry standards."
                        />

                    </Row>
                </Col>

            </Row>


        </div>
    )
}

export default Accreditation