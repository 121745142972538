import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Landing from './pages/landing/Landing';
import AppRoute from './appRoute/AppRoute';



function App() {
  return (
    <BrowserRouter>
     <AppRoute />
    </BrowserRouter>
  );
}

export default App;
