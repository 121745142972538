import { Button, Col, Image, Menu, Row } from 'antd'
import React, { useState } from 'react'
import logo from '../../images/Ddsgnr Library.png'


const items = [
    {
        label: 'Home',
        key: '1',

    },
    {
        label: 'Accreditation',
        key: '2',


    },
    {
        label: 'About Us',
        key: '3',


    },
    {
        label: 'Programs',
        key: '4',


    },
    {
        label: 'Contact',
        key: '5',


    },
]
const NavBar = () => {
    const [current, setCurrent] = useState('1');
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    return (

        <Row className='nav ' justify={'space-between'}>
            <Col>
                <Image src={logo} preview={false} />
            </Col>
            <Col xxl={13} xl={13} lg={14} md={13} sm={13}>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            </Col>
            <Col>
                <Button type='link' className='me-4'>Login</Button>
                <Button type='primary' >Register</Button>
            </Col>

        </Row>

    )
}

export default NavBar