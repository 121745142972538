import React, { Fragment } from 'react'
import Hero from './Hero'
import NavBar from '../../layout/mainLayout/NavBar'
import './Landing.scss'
import { WhyGreatLakes } from './WhyGreatLakes'
import About from './About'
import Account from './Account'
import Opinions from './Opinions'
import Programs from './Programs'
import Accreditation from './Accreditation'
import ContactUs from './ContactUs'
import ProgramsNew from './ProgramsNew'


const Landing = () => {
  return (
    <div className={'landing'}>
    
    
      <Hero />
      <Accreditation />
      <About />
      <WhyGreatLakes />
   
      <ProgramsNew />
      <Account/>
      <Opinions />
      <ContactUs/>
      
     
   
    </div>
  )
}

export default Landing