import { Button, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { IoCheckmarkCircle } from "react-icons/io5";


const About = () => {
    return (
        <div className='about myContainer' id="about">

            <Title className='bigTitle text-center'>About Great Lakes Online</Title>
            <Row justify={'space-between'} className='mainRow'  >
                <Col span={13}>
                    <Title className='title'><span className='span'>Great Lakes College of Science and Technology </span>is registered as a private career college under the Private Career Colleges Act, 2005 Ontario, Canada.
                    </Title>
                    <Title className='title'><span className='span'>Great Lakes Online </span>is a division of Great Lakes College, and it is also a member of the National Association of Career Colleges (NACC), Career Colleges of Ontario (CCO), and the Technical Standards and Safety Authority (TSSA).
                    </Title>
                   <Row gutter={20} className='mt-5'>
                    <Col >
                    <IoCheckmarkCircle color='#376386' size={36}/>

                    </Col>
                    <Col span={20}>
                    <Title className='paragraph'>
                    Our programs are designed with a focus on real-world applications, bridging the gap between theory and practice.
                    </Title>
                    </Col>
                   </Row>
                   <Button type='primary' className='mt-4'>Register Now</Button>
                </Col>
                <Col span={11} className='text-end'>
                    <svg className='logo' width="567" height="588" viewBox="0 0 567 588" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.19">
                            <path d="M24.9097 563.274C14.4359 563.001 6.06816 554.467 6.00085 543.99L4.36254 289.006C4.25043 271.556 25.4242 262.827 37.6415 275.287L294.182 536.925C306.4 549.385 297.257 570.383 279.812 569.928L24.9097 563.274Z" fill="url(#paint0_linear_139_13678)" />
                            <path d="M20.1276 191.594C18.7651 190.173 17.9927 188.289 17.9666 186.321L15.8804 29.3983C15.7923 22.7702 21.2447 17.4046 27.8705 17.5989L188.889 22.3218C190.925 22.3815 192.856 23.2386 194.266 24.7085L341.697 178.4C349.12 186.138 348.864 198.429 341.126 205.852L195.09 345.939C187.352 353.362 175.061 353.107 167.638 345.368L20.1276 191.594Z" fill="url(#paint1_linear_139_13678)" />
                            <path d="M218.928 393.192L386.723 225.973L548.668 388.476C512.056 459.23 454.586 517.025 384.038 554.034L380.873 555.694L218.928 393.192Z" fill="url(#paint2_linear_139_13678)" />
                            <path d="M495.183 254.193L326.922 87.2057L267.01 17.852L309.422 18.4896C363.508 19.3026 415.985 37.0166 459.5 69.1492L462.338 71.2448C478.85 83.4381 493.676 97.7631 506.43 113.846C542.567 159.418 560.326 216.871 556.207 274.886L553.319 315.558L495.183 254.193Z" fill="url(#paint3_linear_139_13678)" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_139_13678" x1="511.793" y1="285.066" x2="133.095" y2="425.091" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0FB3ED" />
                                <stop offset="0.996" stop-color="#0096D8" />
                                <stop offset="1" stop-color="#0096D8" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_139_13678" x1="556.22" y1="298.142" x2="4.34498" y2="289.846" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#565656" />
                                <stop offset="1" stop-color="#8C8383" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_139_13678" x1="126.805" y1="567.629" x2="526.632" y2="390.837" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0FB3ED" />
                                <stop offset="1" stop-color="#164EAB" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_139_13678" x1="358.207" y1="20.1902" x2="422.524" y2="311.673" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FFD056" />
                                <stop offset="1" stop-color="#FFB400" />
                            </linearGradient>
                        </defs>
                    </svg>


                </Col>

            </Row>
        </div>
    )
}

export default About