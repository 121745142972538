import { Card, Col, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import trans from '../../images/transprtation.jpeg'
import ai from '../../images/ai.jpeg'
import it from '../../images/it.jpeg'
import pm from '../../images/pm.jpeg'
import ep from '../../images/ep.jpeg'
import { Swiper, SwiperSlide } from 'swiper/react';


const ProgramCard = ({ imageSrc, title }) => {
    return (
        // <Col span={10}>
            <Card rootClassName='myCard'>
                <Image src={imageSrc} className='cusImage' width={'100%'} preview={false} />
                <div className="gradient-overlay">
                    <Title className='font-18 color-white'>
                        {title}
                        <br />
                        Program
                    </Title>
                </div>
            </Card>
        // </Col>
    );
};

const ProgramsNew = () => {
    const programsData = [
        {
            image: trans,
            title: 'Transportation Technologies'
        },
        {
            image: ai,
            title: 'Artificial Intelligence'
        },
        {
            image: it,
            title: 'Information Technology'
        },
        {
            image: pm,
            title: 'Project Management'
        },
        {
            image: ep,
            title: 'English Program'
        },
     
    ];



    return (
        <div className='ProgramsNew'>
            <svg className='orange' width="296" height="210" viewBox="0 0 296 210" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_399_1462)">
                    <g filter="url(#filter0_b_399_1462)">
                        <path d="M33.6996 -184.456C-0.511115 -123.357 -9.07524 -51.1708 9.89123 16.2237C28.8577 83.6182 73.8011 140.7 134.834 174.911C195.868 209.123 267.991 217.662 335.339 198.65C402.687 179.638 459.742 134.632 493.953 73.5336L263.826 -55.4612L33.6996 -184.456Z" fill="url(#paint0_radial_399_1462)" />
                    </g>
                </g>
                <defs>
                    <filter id="filter0_b_399_1462" x="-101.265" y="-285.733" width="696.496" height="595.579" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="50.6385" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_399_1462" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_399_1462" result="shape" />
                    </filter>
                    <radialGradient id="paint0_radial_399_1462" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(262.078 478.595) rotate(-96.4315) scale(883.22 936.897)">
                        <stop stop-color="#FFD056" stop-opacity="0.5" />
                        <stop offset="1" stop-color="#FFB400" stop-opacity="0" />
                    </radialGradient>
                    <clipPath id="clip0_399_1462">
                        <rect width="296" height="210" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <Title className='font-32 color-darkGrey bold-700 '>
                Our Programs
            </Title>
            <Title className='font-18 color-grey bold-400 line-32'>
                Our programs are designed with a focus on real-world applications, bridging the gap between theory and practice.
                <br />
                A choice that makes the difference
            </Title>
            {/* <Row className='mt-5'> */}
                <Swiper
                className='mt-5'
                    spaceBetween={30}
                    slidesPerView={2.5}
                >
                    <Row>
                    {programsData.map((program, index) => (
                        <SwiperSlide key={index}>
                            <ProgramCard
                                imageSrc={program.image}
                                title={program.title}
                            />
                        </SwiperSlide>
                    ))}
                    </Row>
                  
                </Swiper>
            {/* </Row> */}

        </div>
    )
}

export default ProgramsNew